import { GarmentSize } from '../types/api-types'

/**
 * Returns the GarmentSize object from Garment according to the modelSizes
 * @param modelSizes The sizes array off the model
 * @param garmentSizes The GarmentSizes from the garment we want to find the size matching the model
 * @returns The matching GarmentSize, or undefined if we didn't find a match
 */
export const getModelMatchingGarmentSize = (modelSizes: string[], garmentSizes: GarmentSize[]) => {
    const regex = new RegExp(
        `(?:^|[^0-9a-zA-Z])(${modelSizes.toString().replaceAll(',', '|')})(?:$|[^0-9a-zA-Z])`
    )
    const foundGarmentSize = garmentSizes.find((garmentSize) => garmentSize.label.match(regex))

    return foundGarmentSize
}

export const isGarmentSizePreorder = (value: string, optionSizes: GarmentSize[]) => {
    if (value) {
        for (const optionSize of optionSizes) {
            if (optionSize.value == value) {
                return optionSize.preorder === true
            }
        }
    }

    let hasStock = false
    let hasPreorder = false
    for (const optionSize of optionSizes) {
        if (optionSize.in_stock !== false) {
            hasStock = true
        }
        if (optionSize.preorder === true) {
            hasPreorder = true
        }
    }

    return hasPreorder && !hasStock
}

export const getGarmentSizeLabel = (value: string, optionSizes: GarmentSize[]) => {
    for (const optionSize of optionSizes) {
        if (optionSize.value == value) {
            return optionSize.label
        }
    }
    return null
}
