// @ts-nocheck

import { DEBUG_MODE } from '../utils/tracking'

// ---- Log exception ----
function logException(e) {
    if (!DEBUG_MODE) {
        return
    }
    if (console && console.log) {
        console.log(e)
        console.log(e.stack)
    }
}

// ---- Execute a request ----
function executeRequest(payload: any, next = null, responses = null) {
    // ---- Executing request ----
    const xhr = new XMLHttpRequest()
    xhr.onload = function () {
        if (next !== null) {
            if (Array.isArray(responses)) {
                responses.push(xhr.responseText)
            }
            next(responses)
        }
    }
    xhr.open(payload.method || 'GET', payload.url, true)
    if (payload.headers) {
        for (const key in payload.headers) {
            const value = payload.headers[key]
            xhr.setRequestHeader(key, value)
        }
    }
    if (payload.data) {
        if (payload.dataType == 'json') {
            xhr.send(JSON.stringify(payload.data))
        } else {
            const formData = new FormData()
            for (const i in payload.data) {
                formData.append(i, payload.data[i])
            }
            xhr.send(formData)
        }
    } else {
        xhr.send()
    }
}

// ---- Execute a jquery ajax request ----
function executeJqueryAjax(payload, next = null, responses = null) {
    // ---- Missing jquery ----
    if (typeof window['jQuery'] === 'undefined') {
        if (Array.isArray(responses)) {
            responses.push(false)
        }
        next(responses)
        return
    }

    // ---- Add functions ----
    payload.success = function (data) {
        if (Array.isArray(responses)) {
            responses.push(data)
        }
        next(responses)
    }
    payload.error = function () {
        if (Array.isArray(responses)) {
            responses.push(false)
        }
        next(responses)
    }

    // ---- Run ----
    const tmpJquery = window.jQuery
    tmpJquery.ajax(payload)
}

// ---- Execute javascript ----
function executeJavascript(payload, next = null, responses = null) {
    let response = null
    try {
        response = eval(payload)
    } catch (e) {
        logException(e)
        response = false
    }
    if (next !== null) {
        if (Array.isArray(responses)) {
            responses.push(response)
        }
        next(responses)
    }
}

// ---- Execute event ----
function executePartnerEvent(partner, payload, next = null, responses = null) {
    // ---- One call per partner ----
    if (Array.isArray(partner)) {
        for (const one of partner) {
            executePartnerEvent(one, payload, next, responses)
        }
        return
    }

    // ---- Execute for one partner only ----
    let response = null
    try {
        // ---- Push into queue ----
        initPartner(partner)
        _partners[partner].queue.push(payload)

        // ---- Dispatch partner's queue if possible ----
        response = dispatchPartner(partner)
    } catch (e) {
        logException(e)
        response = false
    }
    if (next !== null) {
        if (Array.isArray(responses)) {
            responses.push(response)
        }
        next(responses)
    }
}

// ---- Execute a list of payloads ----
export function executePayloads(payloads, final: (responses: any) => void = null, responses = []) {
    // ---- End condition ----
    if (!Array.isArray(payloads) || !payloads.length) {
        if (final !== null) {
            return final(responses)
        }
        return
    }

    // ---- Get one ----
    const one = payloads[0]
    const nextPayloads = payloads.slice(1)

    // ---- Preparation du next ----
    const next = function (nextResponses) {
        executePayloads(nextPayloads, final, nextResponses)
    }

    // ---- En fonction du type ----
    switch (one.type) {
        case 'request':
            executeRequest(one.payload, next, responses)
            break
        case 'jquery_ajax':
            executeJqueryAjax(one.payload, next, responses)
            break
        case 'javascript':
            executeJavascript(one.payload, next, responses)
            break
        case 'event':
            executePartnerEvent(one.partner, one.payload, next, responses)
            break
    }
}
