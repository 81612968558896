import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SettingsSlice {
    hasMultisize?: boolean
}

const initialState: SettingsSlice = {
    hasMultisize: undefined,
}

// Slice storing the config from data attribute
export const settingsSlice = createSlice({
    initialState,
    name: 'settings',
    reducers: {
        setHasMultisize: (state: SettingsSlice, action: PayloadAction<boolean>) => {
            state.hasMultisize = action.payload
        },
    },
})

// ----- Actions -----
export const { setHasMultisize } = settingsSlice.actions

export default settingsSlice.reducer
