export const formattedHeight = (height: number | string | null, format: string | null = null) => {
    if (height === null) {
        height = 0
    }
    if (format === null) {
        format = 'm'
    }
    if (format == 'm') {
        return `${Math.floor(parseInt(height as string) / 100)}m${parseInt(height as string) % 100}`
    } else if (format == 'i') {
        let inches = Math.floor(parseInt(height as string) * 0.393700787)
        const feet = Math.floor(inches / 12)
        inches = inches % 12
        return `${feet}' ${inches}"`
    }
    return `${Math.floor(parseInt(height as string) / 100)}m${parseInt(height as string) % 100}`
}
