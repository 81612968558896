import { EyeOutlined } from '@ant-design/icons'
import { Alert, Button, Row } from 'antd'
import React from 'react'

interface CustomRadioGroupProps {
    options: { label?: string; value: string | number }[]
    value?: string | number
    onChange?(newValue: string | number): void
    label?: string
}

export default function CustomRadioGroup(props: CustomRadioGroupProps) {
    const { options, value, onChange, label } = props

    if (options.length === 0) {
        return null
    }

    return (
        <div className='custom-radio-group--container'>
            {label && (
                <Alert
                    className='custom-radio-group--label'
                    message={label}
                    icon={<EyeOutlined className='custom-radio-group--label-icon' />}
                    showIcon
                />
            )}

            <Row className='custom-radio-group--button-container'>
                {options.map((option) => (
                    <Button
                        key={option.value}
                        className={`custom-radio-group--button${
                            value === option.value ? '-active' : ''
                        }`}
                        onClick={onChange ? () => onChange(option.value) : undefined}
                    >
                        {option.label || option.value}
                        {value === option.value && (
                            <EyeOutlined className='custom-radio-group--button-icon' />
                        )}
                    </Button>
                ))}
            </Row>
        </div>
    )
}
