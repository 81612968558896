import { executePayloads } from './execute'
import { Garment } from '../types/api-types'

const safeJsonParse = (jsonString: string) => {
    try {
        const parsedDatas = JSON.parse(jsonString)
        return parsedDatas
    } catch (e) {
        return null
    }
}

export const addGarmentSizesToCart = (params: any[], callback: (success: boolean) => void) => {
    // ---- Debug ----
    for (const one of params) {
        console.log(one.garment.garment_id, one.currentSize)
    }

    // ---- Gemo ----
    if (window.location.hostname.match(/(^|\.)gemo\.fr$/)) {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer_id = size.offer_id
                }
            }

            // ---- Url de post ----
            const postUrl = '/ajax.V1.php/fr_FR/Rbs/Commerce/Cart/Lines'

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: postUrl,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json, text/plain, */*',
                        'X-HTTP-Method-Override': 'POST',
                    },
                    dataType: 'json',
                    data: {
                        websiteId: 100199,
                        sectionId: 112008,
                        pageId: 100718,
                        data: {
                            lines: [
                                {
                                    productId: parseInt(offer_id),
                                    category: 'shippingCategories',
                                    successModal: {
                                        pageFunction: 'Rbs_Catalog_ProductAddedToCart',
                                        themeName: 'Project_Gemotheme',
                                    },
                                    storeId: 0,
                                },
                            ],
                            clientDate: new Date().toString(),
                        },
                        detailed: false,
                        URLFormats: 'canonical',
                        visualFormats: 'shortCartItem',
                        referer: window.location,
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        executePayloads(payloads, (responses) => {
            if (responses === false) {
                return callback(false)
            }
            const response = responses.pop()
            const parsedResponse = safeJsonParse(response)
            if (
                !parsedResponse ||
                !parsedResponse.dataSets ||
                !parsedResponse.dataSets.linesAdded ||
                !parsedResponse.dataSets.linesAdded.length ||
                !parsedResponse.dataSets.common ||
                !parsedResponse.dataSets.common.lineCount
            ) {
                return callback(false)
            }
            executePayloads([
                {
                    type: 'javascript',
                    payload: `jQuery('.badge').text('${parsedResponse.dataSets.common.lineCount}');`,
                },
            ])
            return callback(true)
        })
    }
    // ---- Claudie Pierlot ----
    else if (window.location.hostname.match(/(^|\.)claudiepierlot\.com$/)) {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { currentSize } = one

            // ---- Url en fonction de l'origin ----
            let postUrl = '/on/demandware.store/Sites-Claudie-FR-Site/fr_FR/Cart-AddProduct'
            if (window.location.pathname.match(/^\/es\//)) {
                postUrl = '/on/demandware.store/Sites-Claudie-ES-Site/es/Cart-AddProduct'
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: postUrl,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    data: {
                        pid: currentSize,
                        quantity: '1',
                        options: '[]',
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        executePayloads(payloads, (responses) => {
            if (responses === false) {
                return callback(false)
            }
            const response = responses.pop()
            const parsedResponse = safeJsonParse(response)
            if (
                !parsedResponse ||
                parsedResponse.error ||
                !parsedResponse.cart ||
                !parsedResponse.cart.numItems
            ) {
                return callback(false)
            }
            executePayloads([
                {
                    type: 'javascript',
                    payload: `jQuery('.minicart-quantity').text('${parsedResponse.cart.numItems}');`,
                },
            ])
            return callback(true)
        })
    }
    // ---- Eileen Fisher ----
    else if (window.location.hostname.match(/(^|\.)eileenfisher\.com$/)) {
        // ---- Recuperation du base url ----
        executePayloads(
            [
                {
                    type: 'javascript',
                    payload: `(function(){ const match = document.body.innerHTML.match(/\\/on\\/demandware\\.store\\/Sites-ef-Site\\/[^/]+\\//); return match ? match[0] : null })()`,
                },
            ],
            (executeResponses) => {
                // ---- Gestion d'erreur ----
                if (
                    executeResponses === false ||
                    executeResponses.length !== 1 ||
                    !executeResponses[0]
                ) {
                    return callback(false)
                }

                // ---- Initialisation ----
                const payloads = []

                // ---- Pour chaque param ----
                for (const one of params) {
                    // ---- Raccourci ----
                    const { garment, currentSize } = one

                    // ---- Recuperation du variation_id ----
                    let offer_id = null
                    for (const size of garment.product_sizes) {
                        if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                            offer_id = size.offer_id
                        }
                    }

                    // ---- Url en fonction de l'origin ----
                    const postUrl = executeResponses[0] + 'Cart-AddProduct'

                    // ---- Ajout au payload ----
                    payloads.push({
                        type: 'jquery_ajax',
                        payload: {
                            url: postUrl,
                            method: 'POST',
                            dataType: 'json',
                            data: {
                                pid: offer_id,
                                quantity: '1',
                                addedLocation: 'closet',
                                options: '[]',
                            },
                        },
                    })
                }

                // ---- Using jquery to handle the basic auth in dev ----
                executePayloads(payloads, function (responses) {
                    if (responses === false) {
                        return callback(false)
                    }
                    const parsedResponse = responses.pop()
                    if (
                        !parsedResponse ||
                        parsedResponse.error ||
                        !parsedResponse.cart ||
                        !parsedResponse.cart.numItems
                    ) {
                        return callback(false)
                    }
                    let escapedHtml = JSON.stringify(
                        String(parsedResponse.currentItemRenderedTemplate)
                    )
                    escapedHtml = escapedHtml.substring(1, escapedHtml.length - 1)
                    executePayloads([
                        {
                            type: 'javascript',
                            payload: `
                                    jQuery('.minicart-quantity').text('${parsedResponse.cart.numItems}').removeClass('d-none');
                                    jQuery('body').trigger('product:afterAddToCart');
                                    if (jQuery(window).width() >= 770) {
                                        jQuery('.minicart .minicart-link').trigger('click');
                                    }
                                    else {
                                        var i = {
                                            class: "fade modal modal-auto modal-center mobile-minicart-modal mobile-banner-top",
                                            id: "mobile-minicart-modalId",
                                            verticalCenter: !0,
                                            modalTitle: "<span></span>",
                                            modalBody: "${escapedHtml}"
                                        }
                                        $("body").trigger("initModal", i), $("#mobile-minicart-modalId").modal("show"), $("#mobile-minicart-modalId .modal-content").css("height", ""), setTimeout((function() {
                                            $("#mobile-minicart-modalId").modal("hide")
                                        }), 3e3)
                                    }
                                `,
                        },
                    ])
                    return callback(true)
                })
            }
        )
    }
    // ---- Default ----
    else {
        setTimeout(() => callback(true), 2000)
    }
}

export const addGarmentSizeToCart = (
    garment: Garment,
    currentSize: string,
    callback: (success: boolean) => void
) => {
    addGarmentSizesToCart([{ garment, currentSize }], callback)
}
