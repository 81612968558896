export const ROOT_ID = 'veesual_switch_model_root'

export const MODAL_ROOT_ID = 'veesual_switch_model_modal_root'

export const API_CONFIG_KEYS = [
    'ignore_missing',
    'ignore_publish',
    'ignore_cache',
    'experience_id',
    'demo_for',
    'dev_mode',
    'force_mwv',
    'look_width',
    'use_accessory_mask',
    'origin',
]

export const DEFAULT_NB_MAX_CAROUSEL_CARDS = 5
