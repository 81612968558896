import { Button, Typography } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import CustomRadioGroup from './CustomRadioGroup'
import { Model, Size } from '../types/api-types'
import useCustomTranslation from '../shared_utils/translation'

const { Paragraph } = Typography

interface MultiSizeProps {
    model: Model
    modelDescription: string
    itemType: string
    onAddToCart(): void
    onValueChange(newSize: Size): void
}

export default function MultiSize(props: MultiSizeProps) {
    const { model, itemType, modelDescription, onAddToCart, onValueChange } = props

    const { t } = useCustomTranslation()

    // ---- Current selected label (dissociated from availability) -----
    const [selectedLabel, setSelectedLabel] = useState<string | undefined>()

    // ---- Current selected Size object ----
    const currentSize = useMemo(
        () =>
            model.look_multisizes && model.look_multisizes[itemType] && selectedLabel
                ? model.look_multisizes[itemType].sizes.find((size) => size.label === selectedLabel)
                : undefined,
        [model, itemType, selectedLabel]
    )

    const multiSizeOptions = useMemo(() => {
        if (!model || !model.look_multisizes || !model.look_multisizes[itemType]) {
            return []
        }

        /**
         * We build a custom options array where the value is the label as we want to select size even
         * if they are not available (and don't have value field)
         */
        return model.look_multisizes[itemType].sizes.map((size) => ({ ...size, value: size.label }))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, itemType])

    const handleMultisizeChange = (newLabel: string) => {
        // ---- State update ----
        setSelectedLabel(newLabel)

        // ---- Get the associated Size object ----
        const newSize =
            model.look_multisizes && model.look_multisizes[itemType]
                ? model.look_multisizes[itemType].sizes.find((size) => size.label === newLabel)
                : undefined

        if (!newSize) {
            return
        }

        // ---- Callback ----
        onValueChange(newSize)
    }

    const handleAddToCart = () => {
        if (!currentSize) {
            return
        }

        onAddToCart()
    }

    // ---- Init the selectedLabel ----
    useEffect(() => {
        if (!model.look_multisizes || !model.look_multisizes[itemType]) {
            return
        }

        setSelectedLabel(model.look_multisizes[itemType].sizes.find((size) => size.current)?.label)
    }, [model, itemType])

    return (
        <>
            <div className='card-carousel--multisize card-carousel--body-container'>
                <Paragraph
                    ellipsis={{
                        rows: 2,
                        tooltip: true,
                    }}
                    className='card-carousel--multisize card-carousel--text'
                >
                    <b>{model.model_display || model.model_name}</b>
                    {` ${modelDescription.slice(1, modelDescription.length)}`}
                </Paragraph>
            </div>

            <div
                className={`card-carousel--multisize card-carousel--cta-container cardModelSizeSelect-${model.model_id}`}
            >
                <CustomRadioGroup
                    options={multiSizeOptions}
                    value={selectedLabel}
                    onChange={handleMultisizeChange}
                    label={t('multisize_label')}
                />

                {multiSizeOptions.length === 0 || !(currentSize?.value && currentSize?.in_stock) ? (
                    <Button type='primary' style={{ width: '100%' }} disabled={true}>
                        {t('not_available')}
                    </Button>
                ) : (
                    <Button type='primary' style={{ width: '100%' }} onClick={handleAddToCart}>
                        {t('add_to_cart')}
                    </Button>
                )}
            </div>
        </>
    )
}
